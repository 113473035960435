var login = {
	inputs: null,
	submitButton: null,
	validationErrors: null,
	init: function(){
		login.validationErrors	= window.validationErrors;
		login.inputs			= $('INPUT');
		login.submitButton		= $('BUTTON[type="submit"]');
		
		login.validationErrors.init('#login-form');
		
		$('#login-form').on('submit', login.login);
		
	},
	login: function(e){
		e.preventDefault();

		// Strip tags
		if($('#email').length) {
			$('#email').val($('#email').val().replace(/(<([^>]+)>)/ig, ""));
		}

		var valid = true;
		var redirect = $(this).data('redirecturl');
		
		login.validationErrors.clear();
		if(login.validationErrors.check() === false){
			valid = false;
			return false;
		}
	
		if(valid){
			var newLoading = $('#ui-loader-horizontal-footer').clone();
			login.submitButton.addClass('hidden').parent().append(newLoading).promise().done(function(){
				newLoading.prepend('<br>').append('<br>').addClass('visible');
				newLoading.css({'top': '-25%', 'left': '43%'}).find('.svg').css({'transform': 'scale(4)'});
			});

			var postDate = $( this ).serialize();
			login.inputs.prop('disabled', true);
			$.ajax({
				method: "POST",
				url: $( this ).attr('action'),
				data: postDate
			})
			.done(function( res ) {
				if(res.success === true){
					window.location.href = redirect;
				}else{
					newLoading.remove();
					login.submitButton.removeClass('hidden');
					login.inputs.prop('disabled', false);
					login.validationErrors.show(res.errors);
				}
			});
			
		}
	}
};

$(document).ready(function() {
	if($('#login-form').length > 0)
		login.init();
});